import React, { Component } from 'react'

export class NewsItem extends Component {
    render() {
        let {title, description,imgUrl,newsUrl,publishedAt,author,source} = this.props;
        return (
        <div className="col-sm-3">
            <div className="card my-2" >
                <img src={imgUrl} className="card-img-top" alt=""/>
                <span className="position-absolute top-0  translate-middle badge rounded-pill bg-danger" style={{left : '50%'}}>{source} </span>
                    <div className="card-body">
                        <h5 className="card-title">{title}</h5>
                        <p className="card-text">{description}</p>
                        <p className="card-text"><small className="text-muted">By {author ? author :  'Unknown'} on {new Date(publishedAt).toGMTString()}</small></p>
                        <a href={newsUrl} className="btn btn-primary">Read More</a>
                    </div>
            </div>
       </div>
        )
    }
}

export default NewsItem
